import {mapMutations} from 'vuex'

export default {
  methods: {
    ...mapMutations({
      setError: 'error/set'
    }),
    handleError (error, defaultError) {
      let handled = true
      if (error.response?.status === 401) {
        this.handleError401(error)
      } else if (error.response?.status === 403) {
        this.handleError403(error)
      } else if (error.response?.status === 413) {
        this.handleError413(error)
      } else if (error.response?.status === 422) {
        handled = false
      } else {
        this.setError({mode: 'global', error, ...defaultError})
      }
      return handled
    },
    handleError401 (error) {
      this.setError({
        mode: 'modal',
        error,
        title: this.$t('You are not authenticated')
      })
      this.$auth.logout(false)
    },
    handleError403 (error) {
      this.setError({
        mode: 'modal',
        error,
        title: this.$t('Error')
      })
    },
    handleError413 (error) {
      this.setError({
        mode: 'modal',
        error,
        title: this.$t('Error'),
        message: this.$t('Deposit content is too large'),
      })
    },
  }
}
